<!-- 收费金额 -->
<template>
<Charts :chartsData="chartsData" ref="charts" />
</template>

<script>
import Charts from "./echarts";
export default {
  props: ["currNode"],
  data() {
    return {
      chartsData: {
        name: "收费金额",
      },
    };
  },
  watch: {
    currNode(val) {
      if (val) {
        this.getData(val)
      }
    },
  },
  components: {
    Charts,
  },

  methods: {
    getData(node) {
      this.$Axios.post(`/statistics/community/getChargeByFeeType`, {
          communityId: node.communityIds,
          beginDate: node.beginDate,
          endDate: node.endDate,
        })
        .then(({ code, data }) => {
          if (code == 200 && data.length) {
            this.drawLine(data);
          }
        });
    },
    drawLine(data) {
      let xAxisData = []
      let yAxisData = [];
      data.forEach(item => {
        xAxisData.push(item.feeType);
        yAxisData.push(item.total)
      });

      let option = {
        title: {
          text: "", //标题,可选
        },
        tooltip: {},
        legend: {
          //可选
          data: ['收费金额'], //柱坐标
          top: "top", //显示位置
        },
        grid: {
          left: '3%',
          right: '3%',
          bottom: '5%',
          containLabel: true
        },
        xAxis: {
          splitLine: { show: false }, //是否显示网格线，默认不显示，可选
          data: xAxisData,
          // axisLabel : {
          //   rotate : "45"
          // }
        },
        yAxis: {
          scale: true,
          splitLine: { show: true }
        }, //默认显示，可选
        series: [{
          name: "收费金额",
          type: "bar",
          barWidth: 30,
          label: { show: true, position: "top" },
          data: yAxisData,
          itemStyle: {
            normal: {
              color: '#768FFF'
            }
          }
        }],
      };
      this.chartsData.option = option;
      this.$nextTick(() => {
        this.$refs.charts.changeEcharts(option);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.charts_child {
  width: 100%;
  height: 350px;
  display: flex;
  flex-direction: row;

  .charts_bg {
    flex: 2;
    height: 350px;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.2);
  }

  .charts_info {
    flex: 1;
    height: 350px;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.2);

    .charts_content_head {
      width: 100%;
      height: 50px;
      display: flex;
      color: #333;
      padding: 0 20px;
      box-sizing: border-box;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      background: rgb(212, 224, 245);
    }
  }
}
</style>
