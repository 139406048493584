// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../assets/images/empty.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".notice[data-v-19b00fa6]{height:390px}.card dd[data-v-19b00fa6]{padding:0 10px}.items[data-v-19b00fa6]{padding:10px 20px;flex-direction:column;box-sizing:border-box;display:flex;border-bottom:1px solid #f1f1f1;position:relative}.items[data-v-19b00fa6]:before{display:block;content:\"\";width:6px;height:6px;border-radius:50%;background-color:#fa4141;position:absolute;top:18px;left:5px}.items h5[data-v-19b00fa6]{height:30px;line-height:30px;font-weight:400;font-size:15px;white-space:nowrap;text-overflow:ellipsis;overflow:hidden}.items span[data-v-19b00fa6]{font-size:14px;height:20px;line-height:20px}.empty[data-v-19b00fa6]{height:390px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%/200px auto}", ""]);
// Exports
module.exports = exports;
