<!-- 维修工单 -->
<template>
<dl class="card">
  <dt>维修工单</dt>
  <dd class="flex">
    <div class="items">
      <div class="big_charts_content">
        <div class="big_sendcode_charts_content">
          <div class="big_sendcode_charts_child">
            {{tableData.untreated}}
          </div>
        </div>
      </div>
      <div class="title_name">未派单</div>
    </div>
    <div class="items">
      <div class="big_charts_content">
        <div class="big_sendcode_charts_content blue">
          <div class="big_sendcode_charts_child blue">
            {{tableData.processing}}
          </div>
        </div>
      </div>
      <div class="title_name">已派单</div>
    </div>
    <div class="items">
      <div class="big_charts_content">
        <div class="big_sendcode_charts_content green">
          <div class="big_sendcode_charts_child green">
            {{tableData.processed}}
          </div>
        </div>
      </div>
      <div class="title_name">已完成</div>
    </div>
  </dd>
</dl>
</template>

<script>
export default {
  props: ["currNode"],
  data() {
    return {
      tableData: {
        untreated: 0,
        processing: 0,
        processed: 0,
      }
    };
  },
  watch: {
    currNode(val) {
      this.getData(val)
    },
  },
  methods: {
    getData(node) {
      this.$Axios
        .post(`/statistics/repair/getStatusCountByParentId`, {
          communityId: node.communityIds,
          beginDate: node.beginDate,
          endDate: node.endDate,
        })
        .then(({ code, data }) => {
          if (code == 200 && data) {
            this.tableData = { ...data };
          }
        });
    }
  },
};
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
  flex-direction: row;

  .items {
    height: 300px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title_name {
      color: #333;
      font-size: 20px;
      margin-top: 10px;
    }

    .big_charts_content {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      display: flex;
      line-height: 150px;
      box-sizing: border-box;
      align-items: center;
      justify-content: center;
      border: solid 5px #FAFAFA;

      .big_sendcode_charts_content {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border: solid 20px #F77878;

        &.green {
          border: solid 20px #44DAD2
        }

        &.blue {
          border: solid 20px #3DA1EC;
        }

        .big_sendcode_charts_child {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          display: flex;
          flex-direction: row;
          align-items: center;
          box-sizing: border-box;
          align-items: center;
          justify-content: center;
          background-color: #FAFAFA;
          color: #F77878;
          font-size: 24px;
          font-weight: bold;

          &.green {
            color: #44DAD2
          }

          &.blue {
            color: #3DA1EC;
          }
        }
      }
    }

  }
}
</style>
