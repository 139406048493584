<template>
<div class="menu">
  <div class="menu_item" :style="{backgroundColor: item.bg}" v-for="(item,index) in list" :key="index" @click="changeEvent(item)">
    <div class="item_top">
      <span>
        {{item.label}}
      </span>
      <i class="el-icon-school"></i>
    </div>
    <div class="item_bottom">
      点击进入
    </div>
  </div>
</div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      list: [{
          label: "收费前台",
          icon: "el-icon-school",
          bg: "#00D0C2",
          code: "Desktop",
        },
        {
          label: this.$t("ownerInfo"),
          icon: "el-icon-school",
          bg: "#4C71F0",
          code: "OwnerInfo",
        },
        {
          label: "员工收费",
          icon: "el-icon-school",
          bg: "#F0854C",
          code: "EmployeesCharges",
        },
        {
          label: "通知公告",
          icon: "el-icon-school",
          bg: "#7E7FD8",
          code: "Notice",
        },
      ],
    }
  },
  methods: {
    ...mapMutations(["goToPage"]),
    changeEvent(tab) {
      const Resources = JSON.parse(localStorage.resources);
      Resources.forEach((resource) => {
        if (resource.children && resource.children.length) {
          resource.children.forEach((item) => {
            if (item.code == tab.code) {
              this.goToPage(item);
            }
          });
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.menu {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;

  .menu_item {
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
    color: #fff;
    box-sizing: border-box;
    width: 24%;
    height: 120px;
    border-radius: 5px;
    padding: 20px;
    cursor: pointer;

    .item_top {
      height: 60px;
      display: flex;
      font-size: 24px;

      span {
        flex: 1;
      }
    }

    .item_bottom {
      font-size: 14px;
    }
  }
}
</style>
