<template>
<div class="workbench">
  <!-- 查询条件 -->
  <div class="search">
    <vxe-form v-bind="searchOptions" @submit="searchEvent" @reset="searchResetEvent"></vxe-form>
  </div>
  <div class="dashboard">
    <div class="top">
      <div class="left">
        <Top />
        <!-- 欠费情况 -->
        <div class="row">
          <Arrearage :currNode="currNode" class="max" />
          <!-- 数据总览 -->
          <DataOverview :currNode="currNode" class="min" />
        </div>
        <!-- 维修工单 -->
        <!-- <div class="row">
          <RepairOrder :currNode="currNode" class="max" />
          <RepairOrderTime :currNode="currNode" class="min" />
        </div> -->
      </div>
      <div class="right">
        <MessageNotice :currNode="currNode" />
        <!-- 维修提醒 -->
        <!-- <RepairOrderTime :currNode="currNode" class="min" /> -->
        <!-- 缴费情况 -->
        <!-- <PaymentStuation :currNode="currNode" /> -->
        <!-- <ContactUs :currNode="currNode" /> -->
      </div>
    </div>

    <div class="bottom">
      <RepairOrder :currNode="currNode" />
      <!-- 维修提醒 -->
      <RepairOrderTime :currNode="currNode" />
      <!-- 投诉建议 -->
      <Complaints :currNode="currNode" />
    </div>

    <div class="bottom">
      <!-- 统计小区的房屋状态 -->
      <HouseState :currNode="currNode" />
      <!-- 统计小区每月的入住人数统计 -->
      <MonthMemberCount :currNode="currNode" />

      <!--统计单小区车位情况-->
      <HouseParkeSpace :currNode="currNode" />

      <!--按照时间范围统计各收费类型的收费金额-->
      <!-- <ChargeByFeeType :currNode="currNode" /> -->
    </div>
  </div>
</div>
</template>

<script>
import { mapActions } from "vuex";
// import Charts from "./charts/echarts";

// 统计单小区车位情况
import HouseParkeSpace from "./Charts/HouseParkeSpace.vue";
// 按照时间范围统计各收费周期内不同收费类型的收费金额
// import CycleChargeByFeeType from "./Charts/CycleChargeByFeeType.vue";

// 按照时间范围统计各时间周期内不同类型的车辆数量
// import CycleCarByType from "./Charts/CycleCarByType.vue";

// 按照时间范围统计各收费类型的收费金额
// import ChargeByFeeType from "./Charts/ChargeByFeeType.vue";

// 统计楼层入住情况
// import HouseFloorOccupancy from "./Charts/HouseFloorOccupancy.vue";

//  统计楼栋入住情况
// import HouseBuildingOccupancy from "./Charts/HouseBuildingOccupancy.vue";

// 统计小区的房屋状态
import HouseState from "./Charts/HouseState.vue";

//统计小区每月的入住人数统计
import MonthMemberCount from "./Charts/MonthMemberCount.vue";

// 欠费情况
import Arrearage from "./Charts/Arrearage.vue";

// 数据总览
import DataOverview from "./Charts/DataOverview.vue";

// 维修工单
import RepairOrder from "./Charts/RepairOrder.vue";

// 维修时间
import RepairOrderTime from "./Charts/RepairOrderTime.vue";
// 投诉建议
import Complaints from "./Charts/Complaints.vue";

// 通知公告
import MessageNotice from "./Charts/MessageNotice.vue";

// 缴费统计
// import PaymentStuation from "./Charts/PaymentStuation.vue";

// 联系我们
// import ContactUs from "./Charts/ContactUs.vue";

// import Center from './center.vue'
// import RadarChart from "./footer/radarChart.vue";
// import HorizontalHistogram from "./footer/horizontalHistogram.vue";
// import PieChart from "./footer/pieChart.vue";
import Top from "./top.vue";

export default {
  components: {
    // ContactUs,
    MessageNotice,
    // PaymentStuation,
    RepairOrderTime,
    Complaints,
    RepairOrder,
    DataOverview,
    HouseParkeSpace,
    Arrearage,
    // CycleChargeByFeeType,
    // CycleCarByType,
    // ChargeByFeeType,
    // HouseBuildingOccupancy,
    HouseState,
    // HouseFloorOccupancy,
    // HorizontalHistogram,
    MonthMemberCount,
    // PieChart,
    // RadarChart,
    Top,
  },
  data() {
    return {
      currNode: null,
      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "communityIds",
            title: "选择小区",
            itemRender: {
              name: "$VxeCommunityTree",
              props: {
                placeholder: "请选择选择小区",
              },
            },
          },
          {
            field: "dateType",
            title: "时间周期",
            visible: false,
            itemRender: {
              name: "$select",
              optionTypeCode: "dateType",
              props: {
                type: "date",
                placeholder: "请选择开始时间",
              },
              events: {
                change: ({ data, property }) => {
                  if (data[property] == "month") {
                    let month = this.$XEUtils.toDateString(new Date(), "yyyy-MM");
                    data["beginDate"] = this.$XEUtils.toDateString(this.$XEUtils.getWhatMonth(month, 0, "first"), "yyyy-MM-dd 00:00:00");
                    data["endDate"] = this.$XEUtils.toDateString(this.$XEUtils.getWhatMonth(month, 0, "last"), "yyyy-MM-dd 23:59:59");
                  } else if (data[property] == "quarter") {
                    data["beginDate"] = this.$XEUtils.toDateString(this.$XEUtils.getWhatMonth(data["beginDate"], 0, "first"), "yyyy-MM-dd 00:00:00");
                    data["endDate"] = this.$XEUtils.toDateString(this.$XEUtils.getWhatMonth(data["beginDate"], 2, "last"), "yyyy-MM-dd 23:59:59");
                  } else if (data[property] == "year") {
                    data["beginDate"] = this.$XEUtils.toDateString(this.$XEUtils.getWhatYear(data["beginDate"], 0, "first"), "yyyy-MM-dd 00:00:00");
                    data["endDate"] = this.$XEUtils.toDateString(this.$XEUtils.getWhatYear(data["beginDate"], 0, "last"), "yyyy-MM-dd 23:59:59");
                  } else if (data[property] == "custom") {
                    data["beginDate"] = this.$XEUtils.toDateString(this.$XEUtils.getWhatYear(data["beginDate"], 0, "first"), "yyyy-MM-dd 00:00:00");
                    data["endDate"] = this.$XEUtils.toDateString(this.$XEUtils.getWhatYear(data["beginDate"], 0, "last"), "yyyy-MM-dd 23:59:59");
                  } else {
                    data["beginDate"] = this.$XEUtils.toDateString(new Date(), "yyyy-MM-dd 00:00:00");
                    data["endDate"] = this.$XEUtils.toDateString(new Date(), "yyyy-MM-dd 23:59:59");
                  }
                }
              }
            },
          },
          {
            field: "beginDate",
            title: "日期",
            visibleMethod({ data }) {
              if (data.dateType == "date") {
                return true
              } else {
                return false
              }
            },
            itemRender: {
              name: "$input",
              props: {
                type: "date",
                labelFormat: "yyyy-MM-dd",
                placeholder: "请选择日期",
              },
              events: {
                change: ({ data, property }) => {
                  data["beginDate"] = this.$XEUtils.toDateString(data[property], "yyyy-MM-dd 00:00:00");
                  data["endDate"] = this.$XEUtils.toDateString(data[property], "yyyy-MM-dd 23:59:59");
                }
              }
            },
          },
          {
            field: "beginDate",
            title: "月份",
            visible: false,
            // visibleMethod({ data }) {
            //   if (data.dateType == "month") {
            //     return true
            //   } else {
            //     return false
            //   }
            // },
            itemRender: {
              name: "$input",
              props: {
                type: "month",
                labelFormat: "yyyy-MM",
                placeholder: "请选择季度",
              },
              events: {
                change: ({ data, property }) => {
                  data["beginDate"] = this.$XEUtils.toDateString(this.$XEUtils.getWhatMonth(data[property], 0, "first"), "yyyy-MM-dd 00:00:00");
                  data["endDate"] = this.$XEUtils.toDateString(this.$XEUtils.getWhatMonth(data[property], 0, "last"), "yyyy-MM-dd 23:59:59");
                }
              }
            },
          },
          {
            field: "beginDate",
            title: "季度",
            visibleMethod({ data }) {
              if (data.dateType == "quarter") {
                return true
              } else {
                return false
              }
            },
            itemRender: {
              name: "$input",
              props: {
                type: "quarter",
                labelFormat: "yyyy年 第q季度",
                placeholder: "请选择季度",
              },
              events: {
                change: ({ data, property }) => {
                  data["beginDate"] = this.$XEUtils.toDateString(this.$XEUtils.getWhatMonth(data[property], 0, "first"), "yyyy-MM-dd 00:00:00");
                  data["endDate"] = this.$XEUtils.toDateString(this.$XEUtils.getWhatMonth(data["beginDate"], 2, "last"), "yyyy-MM-dd 23:59:59");
                }
              }
            },
          },
          {
            field: "beginDate",
            title: "年",
            visibleMethod({ data }) {
              if (data.dateType == "year") {
                return true
              } else {
                return false
              }
            },
            itemRender: {
              name: "$input",
              props: {
                type: "year",
                labelFormat: "yyyy年",
                placeholder: "请选择年",
              },
              events: {
                change: ({ data, property }) => {
                  data["beginDate"] = this.$XEUtils.toDateString(this.$XEUtils.getWhatYear(data[property], 0, "first"), "yyyy-MM-dd 00:00:00");
                  data["endDate"] = this.$XEUtils.toDateString(this.$XEUtils.getWhatYear(data["beginDate"], 0, "last"), "yyyy-MM-dd 23:59:59");
                }
              }
            },
          },
          {
            field: "beginDate",
            title: "开始时间",
            visibleMethod({ data }) {
              if (data.dateType == "custom") {
                return true
              } else {
                return false
              }
            },
            itemRender: {
              name: "$input",
              props: {
                type: "date",
                labelFormat: "yyyy-MM-dd",
                placeholder: "请选择开始时间",
              },
              events: {
                change: ({ data, property }) => {
                  data["beginDate"] = this.$XEUtils.toDateString(data[property], "yyyy-MM-dd 00:00:00");
                }
              }
            },
          },
          {
            field: "endDate",
            title: "结束时间",
            visibleMethod({ data }) {
              if (data.dateType == "custom") {
                return true
              } else {
                return false
              }
            },
            itemRender: {
              name: "$input",
              props: {
                type: "date",
                labelFormat: "yyyy-MM-dd",
                placeholder: "请选择结束时间",
              },
              events: {
                change: ({ data, property }) => {
                  data["endDate"] = this.$XEUtils.toDateString(data[property], "yyyy-MM-dd 23:59:59");
                }
              }
            },
          },
          {
            align: "center",
            itemRender: {
              name: "$buttons",
              children: [{
                props: {
                  type: "submit",
                  content: "查询",
                  status: "primary",
                },
              }],
            },
          },
        ],
        data: {
          buildingCode: "",
          type: "day",
          dateType: "month",
          beginDate: this.$XEUtils.toDateString(this.$XEUtils.getWhatMonth(new Date(), 0, "first"), "yyyy-MM-dd 00:00:00"),
          endDate: this.$XEUtils.toDateString(this.$XEUtils.getWhatMonth(new Date(), 0, "last"), "yyyy-MM-dd 23:59:59"),
        },
      },
    };
  },
  created() {
    this.getSysDictEvent();
  },
  methods: {
    ...mapActions(["getSysDictService"]),

    // 获取数据字典
    getSysDictEvent() {
      this.getSysDictService({
          params: ["dateType"]
        })
        .then(({ code, data }) => {
          if (code == 200) {
            this.searchOptions.items = [...this.$Tools.buildDataByDicts(this.searchOptions.items, data, { value: "code", label: "name" })];
          }
        });
    },

    // 搜索功能
    searchEvent({ data }) {
      this.currNode = { ...data };
    },

    // 搜索重置功能
    searchResetEvent() {
      this.currNode = { ...data };
    },
  },
};
</script>

<style lang="scss">
.workbench {
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: auto;

  .search {
    padding: 0 10px;
  }

  .dashboard {
    flex: 1;
    padding: 0 10px;

    .card {
      display: flex;
      flex-direction: column;
      background-color: #fff;
      box-sizing: border-box;
      box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.2);
      border-radius: 5px;
      overflow: hidden;
      margin-bottom: 20px;

      dt {
        width: 100%;
        height: 40px;
        display: flex;
        padding: 0 10px;
        box-sizing: border-box;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background: rgb(212, 224, 245);
        text-shadow: 1px 1px 1px rgba(255, 255, 255, 1);
      }

      dd {
        flex: 1;
      }
    }

    .top {
      display: flex;
      flex-direction: row;

      .right {
        width: 300px;
        padding-top: 10px;
        display: flex;
        flex-direction: column;
      }

      .left {
        padding-right: 20px;
        flex: 1;
        display: flex;
        flex-direction: column;

        .row {
          display: flex;
          align-content: center;
          justify-content: space-between;
          box-sizing: border-box;
          // box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);

          .max {
            width: calc(66% - 10px);
          }

          .min {
            width: calc(34% - 10px);
            min-width: 300px;
          }
        }
      }
    }

    .bottom {
      display: flex;
      background-color: #fff;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;

      .card {
        width: 32.5%;
      }
    }
  }
}
</style>
