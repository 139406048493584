<!-- 联系我们-->
<template>
<div class="card">
  <dt>
    <span>{{chartsData.name}}</span>
  </dt>
  <dd class="charts">
    <div ref="chart" class="chart" v-if="option"></div>
  </dd>
</div>
</template>

<script>
export default {
  props: ["chartsData"],
  data() {
    return {
      option: null
    }
  },
  watch: {
    chartsData(newVal, oldVal) {
      if (newVal.option) {
        this.changeEcharts(newVal.option)
      }
    }
  },
  methods: {
    changeEcharts(data) {
      this.option = data;
      if (this.$refs.chart) {
        let chart = this.$echarts.init(this.$refs.chart)
        chart.setOption(data);
        window.addEventListener("resize", function () {
          chart.resize();
        });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.charts {
  min-height: 240px;
  background: url("../../../../../assets/images//empty.png") no-repeat center / 200px auto;

  .chart {
    width: 100%;
    height: 260px;
    background: #fff;
  }
}
</style>
