<!-- 小区车位情况 -->
<template>
<Charts :chartsData="chartsData" ref="charts" />
</template>

<script>
import Charts from "./echarts";

export default {
  props: ["currNode"],
  data() {
    return {
      chartsData: {
        name: "小区车位情况",
      },
    };
  },
  watch: {
    currNode(val) {
      if (val) {
        this.getData(val);
      }
    },
  },
  components: {
    Charts,
  },
  methods: {
    getData(node) {
      this.$Axios.post(`/statistics/community/getParkingSpace`, {
          communityId: node.communityIds,
        })
        .then(({ code, data }) => {
          if (code == 200) {
            this.drawLine(data);
          }
        });
    },
    drawLine(data) {
      let option = {
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: 'vertical',
          left: '1%',
          bottom: "center",
        },
        series: [{
          name: data.communityName,
          type: "pie",
          radius: ['50%', '70%'],
          // 饼图位置参数
          center: ["60%", "50%"],
          labelLine: {
            show: true
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          },
          data: [
            { value: data.parkingTotal, name: "车位总数" },
            { value: data.groundCount, name: "地面车位数量" },
            { value: data.undergroundCount, name: "地下车位数量" },
            { value: data.chargingCount, name: "充电桩车位数量" },
            { value: data.ordinaryCount, name: "普通车位数量" },
            { value: data.roomCount, name: "总户数" },
          ],
        }, ],
      };
      this.chartsData.option = option;
      this.$nextTick(() => {
        this.$refs.charts.changeEcharts(option);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
