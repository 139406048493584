<!-- 数据总览 -->
<template>
<dl class="card">
  <dt>数据总览</dt>
  <dd class="info">
    <div class="items">
      <span class="label"> 楼栋: </span>
      <span>{{tableData.floorCount}}个</span>
    </div>
    <div class="items">
      <span class="label"> 总户数: </span>
      <span>{{tableData.roomCount}}个</span>
    </div>
    <div class="items">
      <span class="label"> 已入住: </span>
      <span>{{tableData.checkInCount}}个</span>
    </div>
    <div class="items">
      <span class="label"> 未入住: </span>
      <span>{{tableData.roomCount || 0 - tableData.checkInCount}}个</span>
    </div>
    <div class="items">
      <span class="label"> 停车场: </span>
      <span>{{tableData.parkingCount}}个</span>
    </div>
    <div class="items">
      <span class="label"> 车位数量: </span>
      <span>{{tableData.spaceCount}}个</span>
    </div>
    <div class="items">
      <span class="label"> 已使用: </span>
      <span>{{tableData.spaceCountH + tableData.spaceCountS}}个</span>
    </div>
    <div class="items">
      <span class="label"> 空闲车位: </span>
      <span>{{tableData.spaceCountF}}个</span>
    </div>
  </dd>
</dl>
</template>

<script>
export default {
  props: ["currNode"],
  data() {
    return {
      tableData: {
        floorCount: 0,
        roomCount: 0,
        checkInCount: 0,
        parkingCount: 0,
        spaceCount: 0,
        spaceCountH: 0,
        spaceCountS: 0,
        spaceCountF: 0,
      }
    };
  },
  watch: {
    currNode(val) {
      if (val) {
        this.getData(val);
      }
    },
  },
  methods: {
    getData(node) {
      this.$Axios.post(`/statistics/community/getDataScreening`, {
          communityId: node.communityIds
        })
        .then(({ code, data }) => {
          if (code == 200) {
            this.tableData = {...data};
          }
        });
    }
  },
};
</script>

<style lang="scss" scoped>
.info {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.items {
  float: left;
  width: 48%;
  height: 50px;
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  color: #333;
  background-color: #fafafa;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  margin-bottom: 10px;
  border-left: solid #768fff 4px;
  box-sizing: border-box;

  span {
    padding: 0 10px;
  }
}
</style>
