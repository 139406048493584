<!-- 统计小区的房屋状态 -->
<template>
<Charts :chartsData="chartsData" ref="charts" />
</template>

<script>
import Charts from "./echarts";
export default {
  props: ["currNode"],
  data() {
    return {
      chartsData: {
        name: "小区房屋状态",
      },
    };
  },
  watch: {
    currNode(val) {
      if (val) {
        this.getData(val);
      }
    },
  },
  components: {
    Charts,
  },
  methods: {
    getData(node) {
      this.$Axios.post(`/statistics/community/getCommunityHouseStatus`, {
          communityIds: [node.communityIds],
        })
        .then(({ code, data }) => {
          if (code == 200 && data.length) {
            this.drawLine(data[0]);
          }
        });
    },
    drawLine(data) {
      let option = {
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: 'horizontal',
          left: 'center',
          bottom: "5%"
        },
        series: [{
          name: data.communityName,
          type: "pie",
          radius: ['40%', '60%'],
          center: ['50%', '40%'],
          labelLine: {
            show: true
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          },
          data: [
            { value: data.checkInCount, name: "入住总户数" },
            { value: data.vacantCount, name: "空置总户数" },
          ],
        }],
      };
      this.chartsData.option = option;
      this.$nextTick(() => {
        this.$refs.charts.changeEcharts(option);
      });
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
