<!-- 收费金额 -->
<template>
<Charts :chartsData="chartsData" ref="charts" />
</template>

<script>
import Charts from "./echarts";
export default {
  props: ["currNode"],
  data() {
    return {
      chartsData: {
        name: "小区入住情况",
      },
    };
  },
  watch: {
    currNode(val) {
      if (val) {
        this.getData(val)
      }
    },
  },
  components: {
    Charts,
  },

  methods: {
    getData(node) {
      this.$Axios.post(`/statistics/community/getCommunityOccupancy`, {
          communityIds: [node.communityIds],
          beginDate: node.beginDate,
          endDate: node.endDate,
        })
        .then(({ code, data }) => {
          if (code == 200 && data.length) {
            this.drawLine(data[0]);
          }
        });
    },
    drawLine(data) {
      let option = {
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: 'horizontal',
          left: 'center',
          bottom: "5%"
        },
        series: [{
          name: data.communityName,
          type: "pie",
          radius: ['40%', '60%'],
          center: ['50%', '40%'],
          labelLine: {
            show: true
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          },
          data: [
            { value: data.checkInCount, name: "入住总户数" },
            { value: data.checkInMemberCount, name: "入住总人数" },
          ],
        }],
      };
      this.chartsData.option = option;
      this.$nextTick(() => {
        this.$refs.charts.changeEcharts(option);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.charts_child {
  width: 100%;
  height: 350px;
  display: flex;
  flex-direction: row;

  .charts_bg {
    flex: 2;
    height: 350px;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.2);
  }

  .charts_info {
    flex: 1;
    height: 350px;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.2);

    .charts_content_head {
      width: 100%;
      height: 50px;
      display: flex;
      color: #333;
      padding: 0 20px;
      box-sizing: border-box;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      background: rgb(212, 224, 245);
    }
  }
}
</style>
