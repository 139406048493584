<!-- 维修提醒 -->
<template>
<dl class="card">
  <dt>维修提醒</dt>
  <dd>
    <div class="repair" v-if="tableData.length">
      <el-timeline>
        <el-timeline-item v-for="(item, index) in tableData" :key="index" :icon="item.icon" :type="item.type" :color="item.status == 3 ? '#00CA56' : '#FF4E4E'" :size=" item.size" :timestamp="item.createTime">
          <div class="contentText">
            【{{item.repairObjName}}】{{item.context}}
          </div>
        </el-timeline-item>
      </el-timeline>
    </div>
    <div class="empty" v-else></div>
  </dd>
</dl>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["currNode"],
  data() {
    return {
      tableData: [],
      // 分页的参数配置
      tablePage: {
        total: 0,
        currentPage: 1,
        pageSize: 5,
      },
    };
  },
  watch: {
    currNode(val) {
      if (val) {
        this.getData(val)
      }
    },
  },
  methods: {
    ...mapActions([
      "getListService",
    ]),
    getData(node) {
      this.loading = true;
      this.getListService({
          service: `/wisdom/repairPool`,
          tablePage: this.tablePage,
          params: {
            parentId: node.communityIds,
            status: "1"
          },
        })
        .then(({ code, data }) => {
          if (code == 200) {
            this.tableData = data && data.records || [];
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.repair {
  height: 300px;
}

.el-timeline {
  margin-top: 20px;
  margin-left: 10px;
}

.contentText {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.empty {
  height: 300px;
  background: url("../../../../../assets/images//empty.png") no-repeat center / 200px auto;
}
</style>
