<!-- 通知通告 -->
<template>
<dl class="card">
  <dt>通知公告</dt>
  <dd>
    <div class="notice" v-if="tableData.length">
      <div class="items" v-for="(item,index) in tableData" :key="index">
        <h5>{{item.title}}</h5>
        <span>{{item.createTime}}</span>
      </div>
    </div>
    <div class="empty" v-else></div>
  </dd>
</dl>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["currNode"],
  data() {
    return {
      tableData: [],
      // 分页的参数配置
      tablePage: {
        total: 0,
        currentPage: 1,
        pageSize: 6,
      },
    };
  },
  watch: {
    currNode(val) {
      if (val) {
        this.getData(val)
      }
    },
  },
  methods: {
    ...mapActions([
      "getListService",
    ]),
    getData(node) {
      this.loading = true;
      this.getListService({
          service: `/wisdom/notice`,
          tablePage: this.tablePage,
          params: {
            parentId: node.communityIds
          },
        })
        .then(({ code, data }) => {
          if (code == 200) {
            this.tableData = data && data.records || [];
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.notice {
  height: 390px;
}

.card {
  dd {
    padding: 0 10px;
  }
}

.items {
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  border-bottom: solid 1px #f1f1f1;
  position: relative;

  &::before {
    display: block;
    content: "";
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #fa4141;
    position: absolute;
    top: 18px;
    left: 5px;
  }

  h5 {
    height: 30px;
    line-height: 30px;
    font-weight: normal;
    font-size: 15px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  span {
    font-size: 14px;
    height: 20px;
    line-height: 20px;
  }
}

.empty {
  height: 390px;
  background: url("../../../../../assets/images//empty.png") no-repeat center / 200px auto;
}
</style>
